import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const OutboundLink = makeShortcode("OutboundLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "utah-opera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#utah-opera",
        "aria-label": "utah opera permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Utah Opera`}</h1>
    <h2 {...{
      "id": "digital-publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#digital-publications",
        "aria-label": "digital publications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Digital Publications`}</h2>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please tap an image below to view the digital publication.`}</strong></p>
    </blockquote>
    {
      /*-------------------- **************** --------------------*/
    }
    {
      /*-------------------- CURRENT PUBLICATION --------------------*/
    }
    {
      /*-------------------- **************** --------------------*/
    }
    <h3 {...{
      "id": "tonights-show",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tonights-show",
        "aria-label": "tonights show permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tonight’s Show`}</h3>
    <Link to="/rigoletto-2023" alt="Link to Rigoletto digital publication" mdxType="Link">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHnQpitCgt5juCG/wD/xAAcEAEAAgIDAQAAAAAAAAAAAAABAAIRIQMSICL/2gAIAQEAAQUC4qlgPgqYxrcIGZ1PP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AURH/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECIRESMP/aAAgBAQAGPwLEnqOy+X//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEQQSExUaH/2gAIAQEAAT8hTFFWLBsenR7GHaPCVLtVcEsG4Ybani/TLWsf/9oADAMBAAIAAwAAABAfBUz/xAAXEQEBAQEAAAAAAAAAAAAAAAAAARFh/9oACAEDAQE/EJ2JTDD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABAR/9oACAECAQE/EJW5n//EACEQAAICAQQCAwAAAAAAAAAAAAERACExQXGRoRBRYcHR/9oACAEBAAE/EHEQJBE+rgIK13ENCfA6RmOPRhQEF1WsOyAJJMFFbGPsw107/pOeYE7xKaF9eP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bb76b144e8b58b83da9de5ed54bb9945/8ac56/rigoletto-2023.webp 240w", "/static/bb76b144e8b58b83da9de5ed54bb9945/d3be9/rigoletto-2023.webp 480w", "/static/bb76b144e8b58b83da9de5ed54bb9945/87ca7/rigoletto-2023.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bb76b144e8b58b83da9de5ed54bb9945/09b79/rigoletto-2023.jpg 240w", "/static/bb76b144e8b58b83da9de5ed54bb9945/7cc5e/rigoletto-2023.jpg 480w", "/static/bb76b144e8b58b83da9de5ed54bb9945/1a144/rigoletto-2023.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bb76b144e8b58b83da9de5ed54bb9945/1a144/rigoletto-2023.jpg",
              "alt": "Rigoletto Cover",
              "title": "Rigoletto Cover",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <div className="homeButtons">Rigoletto</div>
    </Link>
    <br />
    <hr />
    {
      /*-------------------- **************** --------------------*/
    }
    {
      /*-------------------- PAST PUBLICATIONs --------------------*/
    }
    {
      /*-------------------- **************** --------------------*/
    }
    <h2 {...{
      "id": "past-publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#past-publications",
        "aria-label": "past publications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Past Publications`}</h2>
    <section className="home-list">
      <Link to="/daughter-regiment-2023" alt="Link to The Daughter of the Regiment digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150.41666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABwW3RGaNGdXC1HO1FoJ//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIDEhMQESH/2gAIAQEAAQUCJRxZTU4yur91yHAUDShrsx4//8QAFhEBAQEAAAAAAAAAAAAAAAAAEBEB/9oACAEDAQE/AS5D/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8BY//EABwQAAICAgMAAAAAAAAAAAAAAAAxARAREiFx4f/aAAgBAQAGPwIxW0x0cQxCFftf/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARECExQVFhobHR/9oACAEBAAE/IQthWB2uM46fKdbWVcCnaxzCbNffHpvsKtntj//aAAwDAQACAAMAAAAQtA9x/8QAGREBAAIDAAAAAAAAAAAAAAAAAQAREKHw/9oACAEDAQE/EAu5UCQ9vH//xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQEh/9oACAECAQE/ENhXV1//xAAfEAEAAgICAgMAAAAAAAAAAAABABEhYTFBEHFRgbH/2gAIAQEAAT8QoC6gOwQhclyltFG4EzCAXt39QXmkByfB6JtwyvtgVUBt0chMDNJOaBrY/INRkbynj//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4a416bf30a072840e256236760aa0925/8ac56/daughter-regiment-2023.webp 240w", "/static/4a416bf30a072840e256236760aa0925/d3be9/daughter-regiment-2023.webp 480w", "/static/4a416bf30a072840e256236760aa0925/87ca7/daughter-regiment-2023.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4a416bf30a072840e256236760aa0925/09b79/daughter-regiment-2023.jpg 240w", "/static/4a416bf30a072840e256236760aa0925/7cc5e/daughter-regiment-2023.jpg 480w", "/static/4a416bf30a072840e256236760aa0925/1a144/daughter-regiment-2023.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4a416bf30a072840e256236760aa0925/1a144/daughter-regiment-2023.jpg",
                "alt": "The Daughter of the Regiment Cover",
                "title": "The Daughter of the Regiment Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">The Daughter of the Regiment</div>
      </Link>
      <Link to="/dutchman-2022" alt="Link to The Flying Dutchman digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150.41666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHwm/Iqp0jZNKsqEh//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRAxASIv/aAAgBAQABBQKt42ovIvdCRJFCdHWv/8QAGBEAAgMAAAAAAAAAAAAAAAAAARACESH/2gAIAQMBAT8BMaWr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHBAAAAYDAAAAAAAAAAAAAAAAAAECEBExIEFh/9oACAEBAAY/ApdPAcVjsWbf/8QAHhAAAQMEAwAAAAAAAAAAAAAAAQARIRAxQYFhcdH/2gAIAQEAAT8hBNCEWwKTwwuGCmdAnKThWzGqRZB0mHxFP//aAAwDAQACAAMAAAAQsDLD/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxBBrclVrf/EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8Qyoh//8QAHRABAQADAAIDAAAAAAAAAAAAAREAITEQQVFhwf/aAAgBAQABPxDqrcv3lwoPuuTAa0KxVSc9O+4G1U1PZiLsw91gbZzCWtmtriFwRrH4H7iRFhJ4P//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3efe3666641bc6283e9ab0cafe88c37b/8ac56/dutchman-cover.webp 240w", "/static/3efe3666641bc6283e9ab0cafe88c37b/d3be9/dutchman-cover.webp 480w", "/static/3efe3666641bc6283e9ab0cafe88c37b/87ca7/dutchman-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3efe3666641bc6283e9ab0cafe88c37b/09b79/dutchman-cover.jpg 240w", "/static/3efe3666641bc6283e9ab0cafe88c37b/7cc5e/dutchman-cover.jpg 480w", "/static/3efe3666641bc6283e9ab0cafe88c37b/1a144/dutchman-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3efe3666641bc6283e9ab0cafe88c37b/1a144/dutchman-cover.jpg",
                "alt": "The Flying Dutchman Cover",
                "title": "The Flying Dutchman Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">The Flying Dutchman</div>
      </Link>
      <Link to="/pirates-2022" alt="Link to Pirates of Penzance digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150.41666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgUD/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDAP/aAAwDAQACEAMQAAAB7KsLypoFh0zbLxg8AR//xAAbEAACAwEBAQAAAAAAAAAAAAABAgADEhEEFP/aAAgBAQABBQJ+9DsZSTljFMqIzkmGvgpJAbzvr57J56WVP//EABcRAQADAAAAAAAAAAAAAAAAABABERL/2gAIAQMBAT8BqDR//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/AWYz/8QAHxAAAgICAQUAAAAAAAAAAAAAAAECESExECJBYXGR/9oACAEBAAY/ApZZp17MjVmX94b8nSST7MeY7NxHdbP/xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFRcbHR4f/aAAgBAQABPyGtsb5uUzoatixttxuQb1CWgC88IV97CuJSvmMN9M2JAXTnolmOR2xT7PyAi9DP/9oADAMBAAIAAwAAABBMy4//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EBjWyMHLb//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAUaJLYF//EABwQAQACAwEBAQAAAAAAAAAAAAEAESExYVFBcf/aAAgBAQABPxA0AKQrW4cJ1DMdqNuXai1o9h1iKuxktxK5KHYj6HbqABluj9olmj6SJkecYE7RBo/cbjdFhTF9IumFC8GXkKBYPVD1atyeHJ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/69bedccf243fcf7b4c5def70df99214c/8ac56/pirates-cover.webp 240w", "/static/69bedccf243fcf7b4c5def70df99214c/d3be9/pirates-cover.webp 480w", "/static/69bedccf243fcf7b4c5def70df99214c/87ca7/pirates-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/69bedccf243fcf7b4c5def70df99214c/09b79/pirates-cover.jpg 240w", "/static/69bedccf243fcf7b4c5def70df99214c/7cc5e/pirates-cover.jpg 480w", "/static/69bedccf243fcf7b4c5def70df99214c/1a144/pirates-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/69bedccf243fcf7b4c5def70df99214c/1a144/pirates-cover.jpg",
                "alt": "Pirates of Penzance Cover",
                "title": "Pirates of Penzance Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">The Pirates of Penzance</div>
      </Link>
      <Link to="/tosca-2022" alt="Link to Tosca digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAD/9oADAMBAAIQAxAAAAHl3m42zDBBkqYUCSv/xAAcEAACAgIDAAAAAAAAAAAAAAABAgARAxMxMkL/2gAIAQEAAQUCAssKlRO2WrKmeRxFyMs3vC1n/8QAFxEBAQEBAAAAAAAAAAAAAAAAABESIf/aAAgBAwEBPwHPEVX/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/AbpSL//EABkQAAMBAQEAAAAAAAAAAAAAAAABIRAxEf/aAAgBAQAGPwImUmcKnkbOs9P/xAAdEAADAQACAwEAAAAAAAAAAAAAAREhMUFRYZHB/9oACAEBAAE/IXISSotuj7RolVEIJBTi5F4N8EmxROc0SYPVMP2GsZ1s/9oADAMBAAIAAwAAABD7CcH/xAAYEQEBAAMAAAAAAAAAAAAAAAAAEQEhUf/aAAgBAwEBPxCN18YYf//EABkRAAIDAQAAAAAAAAAAAAAAAAARARBBUf/aAAgBAgEBPxDATtFJ/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBYXGRof/aAAgBAQABPxD53wzHUQY+57PWVtowE4qNy7coFHUyYogHTzNAqTydMxIuAtPrCESmtvMgjLxBXz+GRFZDT0YkJFV6uf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3f4f5b7f4305a3d42c4f7aad69e6c274/8ac56/tosca-cover.webp 240w", "/static/3f4f5b7f4305a3d42c4f7aad69e6c274/d3be9/tosca-cover.webp 480w", "/static/3f4f5b7f4305a3d42c4f7aad69e6c274/87ca7/tosca-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3f4f5b7f4305a3d42c4f7aad69e6c274/09b79/tosca-cover.jpg 240w", "/static/3f4f5b7f4305a3d42c4f7aad69e6c274/7cc5e/tosca-cover.jpg 480w", "/static/3f4f5b7f4305a3d42c4f7aad69e6c274/1a144/tosca-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3f4f5b7f4305a3d42c4f7aad69e6c274/1a144/tosca-cover.jpg",
                "alt": "Flight Cover",
                "title": "Flight Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Tosca</div>
      </Link>
      <Link to="/flight-2022" alt="Link to Flight digital publication" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABAAFAgP/xAAYAQACAwAAAAAAAAAAAAAAAAABAwACBP/aAAwDAQACEAMQAAABKw65ZF6WdmIjM7et9mQP/8QAHRAAAgICAwEAAAAAAAAAAAAAAQIAEQMSEyEiMf/aAAgBAQABBQKqOP4EMbpMHkbwncKSoLwPU5JsZ//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhES/9oACAEDAQE/AYwopmmaP//EABcRAQEBAQAAAAAAAAAAAAAAAAACEhH/2gAIAQIBAT8Bu2nGX//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARARIP/aAAgBAQAGPwJcoyDZtV//xAAeEAACAwABBQAAAAAAAAAAAAAAAREhMUFRYXGRsf/aAAgBAQABPyGSE2LQgQQ73TjBPB9H0hfIFNb74NmrQ6kSjNL7JOT/2gAMAwEAAgADAAAAEHzjff/EABgRAAIDAAAAAAAAAAAAAAAAAAAQESFR/9oACAEDAQE/ELjqen//xAAYEQADAQEAAAAAAAAAAAAAAAAAASERMf/aAAgBAgEBPxCA64NHDB//xAAcEAEAAwADAQEAAAAAAAAAAAABABEhQVFxMYH/2gAIAQEAAT8QqAKY1jLgU93xBIV+kriHDvSUipZQVvANy12qYzfstrT2BkfLIeIt36ESEqHUqS1gLxrIsVSs/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0715c937ca86e5d9a15d90cd9d7b8e3e/8ac56/flight-cover.webp 240w", "/static/0715c937ca86e5d9a15d90cd9d7b8e3e/d3be9/flight-cover.webp 480w", "/static/0715c937ca86e5d9a15d90cd9d7b8e3e/87ca7/flight-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0715c937ca86e5d9a15d90cd9d7b8e3e/09b79/flight-cover.jpg 240w", "/static/0715c937ca86e5d9a15d90cd9d7b8e3e/7cc5e/flight-cover.jpg 480w", "/static/0715c937ca86e5d9a15d90cd9d7b8e3e/1a144/flight-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0715c937ca86e5d9a15d90cd9d7b8e3e/1a144/flight-cover.jpg",
                "alt": "Flight Cover",
                "title": "Flight Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Flight</div>
      </Link>
      <Link to="/seville-2021" alt="Link to The Barber of Seville 2021 Utah Opera publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgH/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQME/9oADAMBAAIQAxAAAAFi9zzrUSie1Td0Q2JB/wD/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIDEhEQ/9oACAEBAAEFAtcUL4DbGZVtrr1LSacYo00dNef/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAwEBPwFClP/EABYRAQEBAAAAAAAAAAAAAAAAAAASEf/aAAgBAgEBPwHVJS//xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhEAIgQWFx/9oACAEBAAY/Aoix7OMBuoT3itSlbP/EACAQAAICAQMFAAAAAAAAAAAAAAABETEhEEFRYXGBkfH/2gAIAQEAAT8hdp4GnE3bkSrQglOfBApLNFBWE8n6NMnCDMSSuqFEXZ60/9oADAMBAAIAAwAAABDr+fD/xAAZEQEBAQADAAAAAAAAAAAAAAABABEhMXH/2gAIAQMBAT8QxFY3zrOu73f/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxB0oSsglXCT/8QAHRABAQEBAQACAwAAAAAAAAAAAREAITFBYVGBof/aAAgBAQABPxAFWj2+mruPRhgo4Pjmt8p9L/dWcNknpznxrcjhl10eSZS9ap0v51AAPHLHj+kEyh0r6PMtbv/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/91bdd19af60e9a6b7269bccbe6ef8920/8ac56/seville-cover.webp 240w", "/static/91bdd19af60e9a6b7269bccbe6ef8920/d3be9/seville-cover.webp 480w", "/static/91bdd19af60e9a6b7269bccbe6ef8920/87ca7/seville-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/91bdd19af60e9a6b7269bccbe6ef8920/09b79/seville-cover.jpg 240w", "/static/91bdd19af60e9a6b7269bccbe6ef8920/7cc5e/seville-cover.jpg 480w", "/static/91bdd19af60e9a6b7269bccbe6ef8920/1a144/seville-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/91bdd19af60e9a6b7269bccbe6ef8920/1a144/seville-cover.jpg",
                "alt": "Utah Opera The Barber of Seville 2021 Cover",
                "title": "Utah Opera The Barber of Seville 2021 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">The Barber of Seville</div>
      </Link>
    </section>
    <blockquote>
      <p parentName="blockquote">{`Utah Opera celebrating 82 years of entertainment. Featuring music spanning 250 years of history-Beethoven, Tchaikovsky, Rachmaninoff, Brahms, Mozart, Bach and more!`}</p>
    </blockquote>
    {
      /******************ADS ADS ADS******************/
    }
    {
      /******************ADS ADS ADS******************/
    }
    {
      /******************ADS ADS ADS******************/
    }
    <OutboundLink href="https://osher.utah.edu/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=utahsymphony" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAYAQACAwAAAAAAAAAAAAAAAAACAwABBP/aAAwDAQACEAMQAAAB05JFS2k6B1EhbHoa4WT/xAAeEAACAgICAwAAAAAAAAAAAAABAgADBBIRIhMhI//aAAgBAQABBQLYGW2fQ2ss8gjv1qtq0Yrv3aV4XK7esZS0QEL/AP/EABoRAAICAwAAAAAAAAAAAAAAAAECABEDEFH/2gAIAQMBAT8BFCWnJkQBb1//xAAXEQADAQAAAAAAAAAAAAAAAAAAASEQ/9oACAECAQE/Aay7/8QAHxAAAgIBBAMAAAAAAAAAAAAAAAECESIDEiExEGFx/9oACAEBAAY/AnkbbxKRFLs4rgy79m6OmUoWXN0/E2vhTZ//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyGkFu9ItbDIIx2iz9ijv9ToWlaQIqHvq4rJPNYiNM9yJcvA7RAgPksfVmv2AkL9n//aAAwDAQACAAMAAAAQW+d8/8QAGREAAwADAAAAAAAAAAAAAAAAAAERECFB/9oACAEDAQE/EIND6BoRSY//xAAYEQADAQEAAAAAAAAAAAAAAAAAARExIf/aAAgBAgEBPxDtWJTotKf/xAAeEAEAAgIDAQEBAAAAAAAAAAABABEhMUFRYYGh8P/aAAgBAQABPxADSrY65r9IRYzQAuFV+xCDeOePPIgKwdGw0wro2US1M0nHkN0mfA2LxETrLDQ/DEXvtRL4vVd81LeItL4Be44eAalktcDCWu9/1wabu07n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5af6749032bf105048da332d251487dd/8ac56/u-of-u.webp 240w", "/static/5af6749032bf105048da332d251487dd/d3be9/u-of-u.webp 480w", "/static/5af6749032bf105048da332d251487dd/87ca7/u-of-u.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5af6749032bf105048da332d251487dd/09b79/u-of-u.jpg 240w", "/static/5af6749032bf105048da332d251487dd/7cc5e/u-of-u.jpg 480w", "/static/5af6749032bf105048da332d251487dd/1a144/u-of-u.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5af6749032bf105048da332d251487dd/1a144/u-of-u.jpg",
              "alt": "University of Utah Lifelong Learning",
              "title": "University of Utah Lifelong Learning",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <OutboundLink href="https://www.jerryseinercadillac.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=utahsymphony" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABAABAwL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAwQC/9oADAMBAAIQAxAAAAHl4XxReORaBaTWRIiwf//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQISEP/aAAgBAQABBQLgeTk6Q9K1Fr1k6ZS+f//EABkRAAIDAQAAAAAAAAAAAAAAAAARAQISQf/aAAgBAwEBPwFx0VRmj//EABcRAQEBAQAAAAAAAAAAAAAAAAEAERD/2gAIAQIBAT8BFteZf//EABYQAQEBAAAAAAAAAAAAAAAAACARMf/aAAgBAQAGPwIQaP/EABwQAAICAgMAAAAAAAAAAAAAAAABEXEhUTFBYf/aAAgBAQABPyFldUhhu0LSLI5+RQlLmxEJ+bI+whUbyf/aAAwDAQACAAMAAAAQB9IN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxC1Oi7af//EABgRAAIDAAAAAAAAAAAAAAAAAAAQEVFh/9oACAECAQE/EKJgQn//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMVFBkdGB/9oACAEBAAE/EBtvPyL4ChXZFHiaNAWtSAFiaSxr1DsRZE2Ngqh9pQEbt+oEArOOIAaW979lK3p5fsVSYdXP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b03bb8d1edd271dd8e530b37482e930e/8ac56/jerry-seiner.webp 240w", "/static/b03bb8d1edd271dd8e530b37482e930e/d3be9/jerry-seiner.webp 480w", "/static/b03bb8d1edd271dd8e530b37482e930e/87ca7/jerry-seiner.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b03bb8d1edd271dd8e530b37482e930e/09b79/jerry-seiner.jpg 240w", "/static/b03bb8d1edd271dd8e530b37482e930e/7cc5e/jerry-seiner.jpg 480w", "/static/b03bb8d1edd271dd8e530b37482e930e/1a144/jerry-seiner.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b03bb8d1edd271dd8e530b37482e930e/1a144/jerry-seiner.jpg",
              "alt": "Jerry Seiner Cadillac",
              "title": "Jerry Seiner Cadillac",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      